// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

*, *::before, *::after {
    box-sizing: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["html, body {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    box-sizing: border-box;\n    overflow: hidden;\n}\n\n*, *::before, *::after {\n    box-sizing: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
